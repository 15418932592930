import * as React from 'react';
import { useTranslation } from '../../../../../../../providers/TranslationProvider';
import jsep from 'jsep';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';

import { ButtonPanel } from './ButtonPanel';
import {
  closeIconStyle,
  listElementsStyle,
  MuiAddCaseText,
} from './styles/formulaGenerator';
import CalculatorLegend from './CalculatorLegend';
import {
  Divider,
  Grid,
  useTheme,
  Autocomplete,
  TextField,
  Chip,
  IconButton
} from '@mui/material';
import {
  caseContainerStyle,
  containerStyle,
  MuiAddCase,
  MuiCaseItems,
  MuiCasesContainer,
  MuiCheckCircleIcon,
  MuiContainer,
  MuiDeleteIcon,
  MuiGridAdd,
  MuiGridButtonPanel,
  MuiRefreshIcon
} from '../styles/caseGenerator';
import axios from '../../../../../../../../api/axios/axiosCartoInstance';
import apiPaths from '../../../../../../../../api/apiPaths';
import { languageMap } from '../../../../../../../../utils/constants';
import { status200 } from '../../../../../../../../api/status.utils';
import { useSelector } from 'react-redux';
import ToastValidation from '../../../../../../../common/ToastValidation';
import { getExpressionType } from './FormulaGenerator';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { parseFormula } from '../../../../../../../../utils/supportComponents';
import { lighten } from '@mui/material/styles';
import { MuiAddIcon } from '../../../styles/common';
import { inputStyle, labelInputStyle } from '../styles/widgetForm';

export function CaseGenerator({methodsForFormula, variablesForFormula, setActiveStep,
                               setOpenToast, setSeverity, setDisabledContinue,
                               goCheckCaseStructure, setMessageToast, tableName,
                               setValidatingStructureCase, setIsCaseGenerationValid, isCaseGenerationValid}) {
  const [cases, setCases] = React.useState([{id: 1, name: '', default: false, formula:[], validate:false }])
  const [formula, setFormula] = React.useState([{id: 1, formula: ''}])
  const [formulaErrors, setFormulaErrors] = React.useState({}); // New state for tracking formula errors
  const [expressionFields, setExpressionFields] = React.useState([{id: 1 , parentID: 1}])
  const [selectedExpresionField, ] = React.useState(1)
  const textInputRefs = React.useRef({});
  const [toggleStates, setToggleStates] = React.useState({});

  const {t} = useTranslation();
  const theme = useTheme();
  const selectedFormulaObject = formula.find((item) => item.id === selectedExpresionField);
  const selectedFormula = selectedFormulaObject ? selectedFormulaObject.formula : '';

  const updateFormula = (id, newFormula) => {
    setFormula(prevFormulas => prevFormulas.map(item =>
      item.id === id ? { ...item, formula: newFormula } : item
    ));
    setFormulaErrors(prevErrors => ({ ...prevErrors, [id]: { error: false, errorMessage: '' }})); // Clear error on update
  };


  const [methods,, setFormulaWithAlias ] = methodsForFormula
  const [columns, action,, , datasetColumn,,,,,,, isValid] = variablesForFormula;

  const [, setIsWide] = React.useState(window.innerWidth > 1300);
  const [selectedValues, setSelectedValues] = React.useState([]);
  const [inputValue, setInputValue] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeCase , setActiveCase] = React.useState(0)
  const widgetsParameters = useSelector((state) => state.app.widgets.filter(widget => widget.type === 'parameters'));
  const [validating, setValidating] = React.useState(false);
  const [message, setMessage] = React.useState();
  const [openAlternatives, setOpenAlternatives] = React.useState(false);
  const [actionCase, setActionCase] = React.useState('')
  const [editChip, setEditChip] = React.useState({ id: null, index: -1, value: '' });
  const inputRef = React.useRef(null);

  const btnValidateRef = React.useRef([]);

  const open = Boolean(anchorEl);

  const handleClear  = (id) => {
    setTempValue('');
    setCases((prevCases) =>
      prevCases.map((item) =>
        item.id === id ? { ...item, formula: [] } : item
      )
    );
  };

  const handleInputChange = (id, newValue) => {
    if (newValue && !columns.includes(newValue)) {
      setTempValue((prev) => ({
        ...prev,
        [id]: (prev[id] || '') + newValue
      }));
      setEditChip((prev) => ({ ...prev, value: (prev.value || '') + newValue }));
      setInputValue('');
    } else {
      setTempValue('');
      setInputValue((prev) => ({
        ...prev,
        [id]: newValue,
      }));
    }
  };

  const handleChange = (event, values, id) => {
    setActiveCase(id);
    setSelectedValues(values);
  }

  const handleClose = () => {
    setAnchorEl(null);
    setOpenAlternatives(false);
    setMessage(null)
  };

  const handleFocus = (id) => setActiveCase(id)

  const initDefaultCase = () => {
    if ( action !== 'update' || !defaultCaseExists ){
      const newCaseId = cases[cases.length - 1].id + 1;
      const defaultCase = {
        id: newCaseId,
        name: '',
        default: true,
        formula: [],
        validate: true
      };
      setCases(prevCases => [...prevCases, defaultCase]);

    } else {
      setCases((prevCases) => prevCases.map((item) =>
          item.default ? { ...item, id: cases.length } : item
        )
      );
    }
  }

  React.useEffect(() => {
    initDefaultCase()
    const handleResize = () => {
      setIsWide(window.innerWidth > 1300);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

const updateCaseName = (id, newName) => {
    const newCases = cases.map(caseItem =>
        caseItem.id === id ? { ...caseItem, name: newName } : caseItem
    );
    setCases(newCases);
};

const addNewCase = () => {
  setActionCase('add')
  const newCaseId = cases[cases.length - 1].id;

  const newCase = {
    id: newCaseId,
    name: '',
    default: false,
    formula: [],
    validate: false
  };

  const newExpressionField = { id: newCaseId, parentID: newCaseId };
  const newFormula = { id: newCaseId, formula: '' };

  //* Agregar el nuevo case
  setCases(prevCases => [...prevCases, newCase]);

  //* Agregar expresion y formula asociada al nuevo case
  setExpressionFields(prevExpressions => [...prevExpressions, newExpressionField]);
  setFormula(prevFormulas => [...prevFormulas, newFormula]);
};

const deleteCase = (caseId) => {
  setActionCase('delete')

  //* Remove the case with the specified id
  setCases(prevCases =>
    prevCases.filter(caseItem => caseItem.id !== caseId));

  //* Remove the expressionFields associated with the case
  setExpressionFields(prevExpressionFields =>
    prevExpressionFields.filter(expression => expression.parentID !== caseId));

  //* Remove the formulas associated with the expression fields of the case
  setFormula(prevFormulas =>
    prevFormulas.filter(formulaItem => formulaItem.id !== caseId));
};

const cleanAlias = (alias) => alias.join('_').replace(/\s+/g, '')
                                   .toLowerCase()
                                   .trim()
                                   .normalize("NFD")
                                   .replace(/[\u0300-\u036f]/g, "")
                                   .replace(/\s*-\s*/g, "-")

const createAlias = (dontSetValue) => {
  let caseParts = [];
  let aliasParts = [];
  // Iterate through each case
  cases.forEach(caseItem => {
    // Skip the default case for now
    if (caseItem.default) return;

    // Get all expression fields for the current case
    const expressionsForCase = expressionFields.filter(expression => expression.parentID === caseItem.id);

    // Create the SQL snippet for the current case
    let caseConditionParts = expressionsForCase.map(expressionField => {
      const formulaItem = formula.find(f => f.id === expressionField.id);
      return formulaItem ? caseItem.formula : null;
    }).filter(Boolean); // Remove any nulls

    // Join the expressions with their toggle states (AND/OR)
    const caseCondition = caseConditionParts.map((part, index) => {
      const toggle = toggleStates[expressionsForCase[index].id - 1];
      return toggle ? `${toggle} ${part} ` : part;
    }).join(' ');
    if (caseCondition) {
      caseParts.push(`WHEN ${caseCondition} THEN '${caseItem.name}'`);
    }
  });

  // Handle the default case
  const defaultCase = cases.find(caseItem => caseItem.default);
  if (defaultCase) {
    caseParts.push(`ELSE '${defaultCase.name}'`);
    aliasParts.push(`Default_`); // Only add the default part
  }
  // Combine the case parts to form the full SQL CASE statement
  const combinedFormula = caseParts.length > 0 ? `CASE ${caseParts.join(' ')} END` : '';

  // Combine the alias parts to form the alias string
  const uniquePart = (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).slice(0, 8);
  const alias = `${cleanAlias(aliasParts)}_${uniquePart}`;

  // Set the final combined formula and alias
  setFormulaWithAlias({ formula: combinedFormula, alias: alias });
  if(dontSetValue) return;
  methods.setValue('datasetColumn', combinedFormula);
};

const specialCases = new Set(['ceil', 'floor', 'log', 'abs', 'min', 'max', 'pow', 'sqrt']);

const formular = (e) => {
  let content;
  const textContent = e.target.textContent;

  if (textContent === 'MIN') {
    content = 'LEAST';
  } else if (textContent === 'MAX') {
    content = 'GREATEST';
  } else {
    content = textContent;
  }

  setSelectedValues((prevSelectedValues) => [...prevSelectedValues, content]);
  let updatedFormula = selectedFormula;

  if (specialCases.has(content)) {
    // Add function with empty parentheses
    updatedFormula = `${updatedFormula}${content}(`;
  }
  else{
    // Normal case, just append the content
    updatedFormula = `${updatedFormula}${content}`;
  }

  updateFormula(selectedExpresionField, updatedFormula);
};

function setExpressionsBasedInDatasetColumn(inputString) {
  const decodeExpression = decodeURIComponent(inputString);
  if(decodeExpression === "CASE ELSE '' END") return

  // Regex patterns for matching CASE statement parts
  const caseRegex = /WHEN\s+(.*?)\s+THEN\s+'(.*?)'/g;
  const elseRegex = /ELSE\s+'(.*?)'/;

  let match;
  let expressionFields = [];
  let formulaObjects = [];
  let cases = [];
  let toggleStates = {};
  let expressionId = 1; // Unique ID for each expression field

  // Find all WHEN-THEN pairs
  while ((match = caseRegex.exec(decodeExpression)) !== null) {
    let conditions = match[1].trim();
    let caseName = match[2].trim();
    let parentID = expressionId; // Assign parentID for the case

      if (conditions) {
        expressionFields.push({ id: expressionId, parentID: parentID, formula: conditions });
        formulaObjects.push({ id: expressionId, formula: conditions });
        cases.push({ id: parentID, name: caseName, default: false, formula:[conditions], validate:true });
        expressionId++; // Increment for the next expression field
      }
  }

  // Handle the ELSE part as the default case
  const elseMatch = decodeExpression.match(elseRegex);
  if (elseMatch) {
    let defaultCaseName = elseMatch[1].trim();
    cases.push({ id: expressionId, name: defaultCaseName, default: true, validate:true }); // Add the default case
  }

  // Update the states with the new structures
  setCases(cases);
  setExpressionFields(expressionFields);
  setFormula(formulaObjects);
  setToggleStates(toggleStates);
}

const validateActiveCase = () => setCases(cases.map(item =>
  item.id === activeCase ? { ...item, validate: true } : item
));

const areAllCasesValidated = (cases) => cases.every(item => item.validate === true);

const validateAndMoveNextStep = () => {
    setValidatingStructureCase(false)
    setActiveStep(3)
  }

const verificationExpression = (type, itemId = undefined) => {
    if ( itemId !== undefined ) {
      setActiveCase( itemId );
    }
    let allFormulasValid = true;
    const caseSintax = methods.getValues('datasetColumn')
    const newFormulaErrors = {};

    try {
      const currentFormula = itemId !== undefined ? cases.find((item) => item.id === itemId).formula[0]
                                              : caseSintax
      const parameters = widgetsParameters.reduce((acc, widget) => {
        widget.params.parametersCatalog.forEach(param => {
          acc[param.propName] = param.value;
        });
        return acc;
      }, {});
      type === 'category' ? setValidating(true) : setValidatingStructureCase(true)
      const expression_type = getExpressionType(type);
      axios.post(`${apiPaths.validateExpression}${expression_type}`,{
        'expression': type === 'category' ? currentFormula : caseSintax,
        'table_name': tableName,
        'parametersCatalog': parameters,
        'lang': languageMap[localStorage.getItem('lng')]
      }, status200).then((resp) => {
        if (resp.data.status === 'success') {
          createAlias()
          type === 'category' ? setValidating(false) : validateAndMoveNextStep()
          setMessage(resp.data);
          setOpenAlternatives(true)
          setIsCaseGenerationValid( true )
        } else {
          setMessage(resp.data);
          type === 'category' ? setValidating(false) : setValidatingStructureCase(false)
          setOpenAlternatives(true);
          setIsCaseGenerationValid( false )
        }
      }).catch((error) => {
        setValidating(false);
        setOpenToast(true);
        setMessageToast(error.response.data.error);
        setSeverity('error');
        setIsCaseGenerationValid( false );
      });
      validateActiveCase()
    } catch (e) {
      console.error('Parsing error:', e.message);
      allFormulasValid = false;
    }

    setFormulaErrors(newFormulaErrors);

    return allFormulasValid;
  };

const partialFormulaValidation = () => {
  // This function seems to be a less strict version of the previous one
  // It's unclear if you want to incorporate error handling here as well
  try {
      const regex = /[+\-/*]/g; // Again, escape the '-' to avoid range interpretation
      const found = formula.match(regex);
      if (!found || found.length <= 1) {
          return false;
      }

      // Assuming 'formula' is the string to be parsed
      jsep(formula);
      return true;
  } catch (e) {
      // Handle partial validation errors if needed
      return false;
  }
};

const deleteLastCharacter = () => {
  const caseIndex = cases.findIndex((item) => item.id === activeCase);
  if (caseIndex === -1) return;

  let formulaCase = tempValue[activeCase] || cases[caseIndex].formula[0];
  if (!formulaCase) return;

  const isLetter = char => /^[a-zA-Z0-9._]$/.test(char);
  let newFormulaCase;

  if (formulaCase.slice(-7).toLowerCase().trim() === 'between') {
    newFormulaCase = formulaCase.slice(0, -7).trim();
  } else if (isLetter(formulaCase.slice(-1))) {
    newFormulaCase = formulaCase.replace(/([a-zA-Z_]+|\d*\.?\d+|\d+\.?\d*)$/, '');
  } else {
    newFormulaCase = formulaCase.slice(0, -1);
  }

  if ( tempValue[activeCase] ) {
    setTempValue((prev) => ({
      ...prev,
      [activeCase]: newFormulaCase
    }));
    const selectedValuesUpdated = [ ... selectedValues];
    selectedValuesUpdated[ selectedValues.length - 1 ] = newFormulaCase;
  }
  else {
    setSelectedValues(selectedValues.slice(0, -1))
    setCases(prevCases =>
      prevCases.map((item, index) =>
        index === caseIndex
          ? { ...item, formula: [newFormulaCase] }
          : item
      )
    );
  }
  setFormula((prevFormula) =>
    prevFormula.map((item) =>
      item.id === activeCase ? { ...item, formula: [newFormulaCase] } : item
    )
  );
};

const showDeleteCaseButton = () =>
  defaultCaseExists && cases.length !== 2 ? true : !defaultCaseExists && cases.length !== 1

const getLabel = (item) => item.default ? 'Default Case' : `Case ${item.id}`

const getChipStyle = (option) => {
  return {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: columns.includes(option) ? 14 : 16,
    color: columns.includes(option) ? '#254957' : '#143440',
    textTransform: columns.includes(option) ? 'inherit' : 'lowercase',
    background: columns.includes(option) ? lighten(theme.palette.primary.main, 0.9)  : '#EEEEEE',
    border: 'none'
  }
}

const defaultCaseExists = React.useMemo(() => {
  return cases.some(caseItem => caseItem.default);
}, [cases]);

React.useEffect(()=>{
  const allValidated = areAllCasesValidated(cases);
  const nameDefaultCase = cases.find((item) => item.default === true)?.name;

  const conditionsToEnable = () => defaultCaseExists && nameDefaultCase !== '' && allValidated;
  setDisabledContinue(!conditionsToEnable());

}, [defaultCaseExists, cases])

React.useEffect(()=>{
  if(goCheckCaseStructure && isCaseGenerationValid) verificationExpression('category_case')
}, [goCheckCaseStructure, isCaseGenerationValid])

React.useEffect(()=>{
    if(action === 'create') return
    if(typeof datasetColumn === 'undefined') return;
    if( Object.keys(toggleStates).length !== 0) return;
    if(datasetColumn.includes('CASE')) setExpressionsBasedInDatasetColumn(datasetColumn)
  }, [datasetColumn]);

React.useEffect(() => {

  if (formula && formula !== '' && partialFormulaValidation()) {
      methods.setValue('datasetColumn', formula === decodeURIComponent(formula));
  }
}, [formula]);

React.useEffect(() => {
  // Check if the default case is not at the end
  const defaultCaseIndex = cases.findIndex(c => c.default);
  const isDefaultCaseAtEnd = defaultCaseIndex === cases.length - 1;

  if (defaultCaseIndex !== -1 && !isDefaultCaseAtEnd) {
    // If a default case exists and it's not at the end, rearrange the cases
    const defaultCase = cases[defaultCaseIndex];
    const newCases = cases.filter((_, index) => index !== defaultCaseIndex); // Remove the default case
    newCases.push(defaultCase); // Add the default case at the end

    setCases(newCases); // Update the cases state
  }
  createAlias();
}, [cases]);

React.useEffect(() => {
  if(isValid && action === 'update'){
    createAlias(true)
  }
}, [isValid])

React.useEffect(() => {
    const selectedValuesString = selectedValues.join(' ');
    if(selectedValuesString){
      setCases((prevCases) =>
        prevCases.map((item) =>
            item.id === activeCase ? { ...item, formula: [selectedValuesString] } : item
        )
      );
      setFormula((prevFormula) =>
        prevFormula.map((item) =>
          item.id === activeCase ? { ...item, formula: selectedValuesString } : item
        )
      );
    }
  }, [selectedValues]);

React.useEffect(() => {

  //* Actualizar el id del case predeterminado a medida que se agreguen cases
  if ( actionCase === 'add' ) {
    const newId = cases[cases.length - 1].id + 1
    setCases((prevCases) => prevCases.map((item) =>
        item.default === true ? { ...item, id: newId } : item
      )
    );
  }

  }, [expressionFields])

  const [tempValue, setTempValue] = React.useState('');
  const handleConfirmValue = () => {
    setIsCaseGenerationValid( false );
    if (tempValue && !selectedValues.includes(tempValue[activeCase])) {
      if (action === 'update' ) {
        setSelectedValues([cases.find((item) => item.id === activeCase).formula, tempValue[activeCase]]);
        setTempValue('');
      }else{
      setSelectedValues([...selectedValues, tempValue[activeCase]]);
      setTempValue((prev) => ({
        ...prev,
        [activeCase]: '',
      }));
      }
    }
  };

  React.useEffect(() => {
    setIsCaseGenerationValid( false )
  }, [tempValue[activeCase]])

  const startEditingChip = (caseId, index, value) => {
    setEditChip({ id: caseId, index, value });
    setTimeout(() => {
      if(inputRef.current){
        inputRef.current.focus()
        inputRef.current.setSelectionRange(value.length, value.length);
      }
    }, 100);
  };
  const handleEditChipChange = (event) => {
    const allowedKeys = /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;"'<>,.?/\\|~` -]$/;
    if (!allowedKeys.test(event.key) && event.key !== 'Backspace' || event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
    if (event.key === 'Backspace') {
      if (editChip.value !== '') {
        setEditChip((prev) => ({ ...prev, value: prev.value.slice(0,-1) }));
        event.preventDefault();
        event.stopPropagation();
      }else {
        handleChange(null, selectedValues.slice(0, -1));
      }
    }else if (allowedKeys.test(event.key)) {
      handleInputChange(null , event.key)
      event.preventDefault();
      event.stopPropagation();
    }
  };
  const saveEditedChip = (event) => {
    if (editChip.id !== null && editChip.index >= 0) {
      const updatedCases = cases.map((item) => {
        if (item.id === editChip.id) {
          const updatedFormula = [...parseFormula(item.formula, columns)];
          updatedFormula[editChip.index] = editChip.value;
          return { ...item, formula: [updatedFormula.join(' ')] };
        }
        return item;
      });
      setCases(updatedCases);
      setEditChip({ id: null, index: -1, value: '' });
      inputRef.current = null;
      event.preventDefault();
      event.stopPropagation();
    }
  };

return(
    <Grid container style={containerStyle}>
      <MuiContainer xs={12} >
        {cases.map((item) => (
          <MuiCaseItems key={`case-item-${item.id}`}>
            <MuiCasesContainer key={`case-container-${item.id}`}>
              <Grid xs={3} style={caseContainerStyle}>
                <TextField
                  fullWidth
                  key={`textfield-${item.id}`}
                  label={getLabel(item)}
                  InputLabelProps={{ style: labelInputStyle }}
                  sx={inputStyle}
                  variant="outlined"
                  value={item.name}
                  onChange={(e) => updateCaseName(item.id, e.target.value)}
                />
              </Grid>
              <Grid xs={9}>
                {!item.default && expressionFields.filter(field => field.parentID === item.id)
                  .map((field, index) => (
                  <div key={`field-div-${field.id}`} style={{display:'flex'}}>
                    <Grid key={`field-grid-${field.id}`} style={{width:'100%'}} >
                      <React.Fragment key={`field-fragment-${field.id}`}>
                        <Autocomplete
                          multiple
                          id={item.id}
                          options={columns.map((option) => option)}
                          value={parseFormula(cases.find((item) => item.id === field.id && !item.default).formula, columns)}
                          onChange={(event, values, reason) => {
                            if ( reason === 'removeOption' ) {
                              event.stopPropagation();
                              event.preventDefault();
                            } else {
                              handleChange(event, values, item.id);
                            }
                          }}
                          onKeyDown={(event) => {
                            setIsCaseGenerationValid(false);
                            if ( event.key === 'Backspace' && event.target.value === '' ) {
                              event.stopPropagation();
                              event.preventDefault();
                              deleteLastCharacter();
                            }
                          }}
                          onFocus={() => handleFocus(field.id)}
                          inputValue={inputValue[field.id] || ''}
                          onInputChange={(event, newInputValue) => {
                           handleInputChange(field.id, newInputValue);
                          }}
                          freeSolo
                          disabled={validating}
                          ListboxProps={{ sx:listElementsStyle }}
                          renderTags={(value, getTagProps) =>
                            <>
                            {value.map((option, index) => (
                              <Chip variant="outlined"
                                    key={index}
                                    sx={getChipStyle(option)}
                                    {...getTagProps({ index })}
                                    label={
                                      editChip.id === field.id && editChip.index === index ? (
                                        <div>
                                          <TextField
                                            value={editChip.value}
                                            variant="standard"
                                            inputRef={inputRef}
                                            onKeyDown={handleEditChipChange}
                                            InputProps={{
                                              sx: {
                                                fontFamily: 'Montserrat',
                                                fontWeight: 400,
                                                fontSize: 16,
                                                color: '#143440',
                                              },
                                            }}
                                          />
                                          <IconButton onClick={saveEditedChip} size="small">
                                            <CheckCircleOutlineIcon />
                                          </IconButton>
                                        </div>
                                    ) : (
                                      option
                                    )
                                    }
                                    onClick={() => startEditingChip(field.id, index, option)}
                                    onDelete={undefined}/>
                            ))}
                              {tempValue && tempValue[field.id] !== '' &&  tempValue[field.id] !== undefined && (
                                <Chip
                                  variant="outlined"
                                  sx={{
                                    fontFamily: 'Montserrat',
                                    fontWeight: 400,
                                    fontSize: 16,
                                    color: '#143440',
                                    background: 'none',
                                    textTransform: 'lowercase',
                                    border: '1px solid #E0E0E0',
                                  }}
                                  label={
                                    <>
                                      {tempValue[field.id]}
                                      <IconButton
                                        onClick={handleConfirmValue}
                                        size="small"
                                        sx={{ ml: 1, p: 0 }}
                                      >
                                        <CheckCircleOutlineIcon fontSize="small" />
                                      </IconButton>
                                    </>
                                  }
                                />
                              )}
                            </>
                          }
                          clearIcon={
                            <CloseIcon
                              fontSize="small"
                              style={{
                                ...closeIconStyle,
                                color: theme.palette.secondary.main,
                                borderColor: theme.palette.secondary.main
                              }}
                              onClick={()=>{handleClear(item.id)}}
                            />
                          }
                          renderInput={(params) => (
                            <TextField
                              ref={el => { if (el) textInputRefs.current[field.id] = el }}
                              name={`datasetColumn-${index}`}
                              error={formulaErrors[field.id]?.error}
                              helperText={formulaErrors[field.id]?.errorMessage}
                              {...params}
                            />
                            )}
                        />
                      </React.Fragment>
                    </Grid>
                    {showDeleteCaseButton() && (
                        <MuiDeleteIcon
                          onClick={() => deleteCase(item.id)}
                        />
                    )}
                    {validating && activeCase === field.id ?
                        (<MuiRefreshIcon disabled={validating}/>) :
                        (<MuiCheckCircleIcon
                          ref={el => { if (el) btnValidateRef.current[field.id] = el }}
                          onClick={() => item.formula.length > 0 ? verificationExpression('category', item.id) : null}
                          disabled={ item.formula.length === 0 }
                        />)}
                    <Grid key={field.id}>
                        <ToastValidation
                          open={openAlternatives}
                          anchorEl={btnValidateRef.current[activeCase]}
                          headerText={t('formule_valid')}
                          textMessage={message}
                          handleClose={handleClose}
                          createAlias={createAlias}
                          setSelectedValues={setSelectedValues}
                          setIsCaseGenerationValid={setIsCaseGenerationValid}
                        />
                    </Grid>
                    <CalculatorLegend
                        open={open}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                    />
                    </div>
                  ))}
              </Grid>
            </MuiCasesContainer>
          </MuiCaseItems>
        ))}
      </MuiContainer>
      <MuiGridAdd item xs={12}>
        <MuiAddCase
          variant='outlined'
          startIcon={<MuiAddIcon/>}
          onClick={addNewCase}
        >
          <MuiAddCaseText
            variant='body2'
            fontWeight={500}
            letterSpacing={0.25}
          >
            {t('add_case')}
          </MuiAddCaseText>
        </MuiAddCase>
      </MuiGridAdd>
      <Divider/>
      <MuiGridButtonPanel item xs={12}>
        <ButtonPanel formular={formular} deleteLastElement={deleteLastCharacter} />
      </MuiGridButtonPanel>
    </Grid>
)
}
