import { GLOBAL } from '../../../../../../../../utils/constants';
import {
  Button,
  Grid,
  Slider,
  StepButton,
  styled,
  Typography,
  TextField,
} from '@mui/material';
import CustomSkeleton from '../../../../../../../common/CustomSkeleton';
import { SwitchFieldWithTheme } from '../../../../../../../common/ReactHooksFormFields';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const MuiStepButton = styled(StepButton)(({ theme }) => ({
  textTransform:'uppercase',
  fontSize: 14,
  fontWeight: 500,
  fontFamily:'Montserrat',
  '& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active':{
    color: GLOBAL.COLOR_THEME.PRIMARY
  },
  '& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed':{
    color: GLOBAL.COLOR_THEME.PRIMARY
  },
  '& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root':{
    color:'#E0E0E0'
  },
  '& .css-1hv8oq8-MuiStepLabel-label':{
    color:'#9E9E9E',
    fontSize: 14,
    fontWeight: 500,
    fontFamily:'Montserrat',
    letterSpacing:'0.1px',
    textTransform:'uppercase'
  },
  '& .css-1hv8oq8-MuiStepLabel-label.Mui-active':{
    color:'#212121',
    fontSize: 14,
    fontWeight: 500,
    fontFamily:'Montserrat',
    letterSpacing:'0.1px',
    textTransform:'uppercase'
  }
}));

export const MuiTypography = styled(Typography)(({ theme }) => ({
  color: '#325D6C',
  marginLeft:'10px'
}));

export const MuiGrid = styled(Grid)(({ theme }) => ({
  marginTop:24,
  marginBottom:24
}));

export const MuiCustomSkeleton = styled(CustomSkeleton)(({ theme }) => ({
  height: 94,
  margin: theme.spacing(-2.2, 0, 1),
}));

export const MuiSwitchFieldWithTheme = styled(SwitchFieldWithTheme)(({ theme }) => ({
  width: 52,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    color: '#757575',
    padding: 0,
    margin: 4,
    '&.Mui-checked': {
      color: '#FFFFFF',
      transform: 'translateX(22px)',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      border: 'none',
      backgroundColor: theme.palette.primary.main,
      opacity: 1,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    background: '#FFFFFF',
    border: '1px solid',
    borderColor: '#757575',
    borderRadius: 18,
  },
}));

export const MuiSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-thumb': {
    borderRadius: '4px',
    width: '4px',
    height: '24px',
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiSlider-track': {
    height: '2px',
    opacity: 0.4,
    marginLeft: '-2px',
    marginTop: '0px',
    color: theme.palette.primary.main,
  },
  '& .MuiSlider-rail': {
    height: '2px',
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiSlider-root': {
    padding: '13px 0',
  },
}));

export const MuiBtnClose = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]
}));

export const MuiHtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: -20,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.error.main,
    maxWidth: 300,
    border: '1px solid grey',
  },
}));

export const MuiBtnAction = styled(Button)(({ theme }) => ({
  padding:'10px 24px 10px 24px',
  borderRadius: '20px',
  color:'#005F64',
  borderColor:'#005F64',
  height:'37px',
  width:'105px',
  '&:hover': {
    borderColor:'#005F64',
  },
}));

export const textFieldCommonStyle = {
  width: '173px',
  height: '56px',
  '& .MuiInputLabelRoot':{
    fontFamily:'Montserrat'
  },
}

export const divCommonStyle = {
  width:'123px',
  marginTop:'0px',
  marginRight: '16px'
}

export const divArrowsActionStyle = {
  display:'flex',
  flexDirection:'column'
}

export const divSwitchStyle = {
  display:'flex',
  marginTop:'-0.4rem'
}

export const textSwitchStyle = {
  display:'flex',
  marginTop:'10px',
  marginLeft:'10px',
}

export const typographyByValueStyle = {
  color: '#325D6C',
  marginLeft:'10px',
  marginTop:'24px',
  marginBottom:'24px',
}

export const divValueRangeStyle = {
  marginLeft:5,
  height:56,
  minWidth:56,
  alignContent:'center',
  border:'1px solid',
  borderColor:'#757575',
  padding:'7px 5px 8px 11px',
  borderRadius:'4px'
}

export const divMenuItemStyle = {
  borderRadius: '16px',
  width: '24px',
  height: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 4,
}

export const selectStyle = {
  height: '56px',
  width: '120px',
  marginLeft:'-5px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#757575',
  },
  '& .MuiListItem-root': {
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .MuiInputBase-input': {
    color: '#424242',
    fontSize:16,
    fontFamily:'Montserrat'
  },
  '& legend': {
    fontSize:10,
  },
}

export const arrowsCommonStyle = {
  cursor:'pointer',
}

export const labelOpacityStyle = {
  color: '#424242',
  fontSize:12,
  fontFamily:'Montserrat',
}

export const fontVisibilityStyle = {
  color: '#424242',
  marginLeft:'3px',
}

export const gridSliderStyle = {
  display:'flex',
  marginLeft:'5px'
}

export const gridRangeStyle = {
  display:'flex',
  marginTop:'-15px',
  marginLeft:'12px'
}

export const inputColorFieldStyle = {
  width: 36,
  height: 24
}

export const MuiTextfield = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: 'auto',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: '16px',
    alignItems: 'flex-start',
    padding: '12px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
  '& textarea': {
    fontFamily: 'Montserrat',
    fontSize: 16,
    color: '#424242',
    lineHeight: '1.5',
    padding: 0,
    resize: 'none',
    overflow: 'auto',
  },
  '& legend': {
    fontSize: 9,
  },
}));