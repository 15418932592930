/* eslint-disable react/require-default-props */
import React, {useEffect, useState} from 'react';
import {PropTypes} from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
  setViewState,
  selectSourceById,
  removeSpatialFilter,
  addSpatialFilter
} from 'lib/react-geo-tool/packages/react-redux/src/';
import WrapperWidgetUI from '../WrapperWidgetUI';
import WidgetWithAlert from '../sources-carto/WidgetWithAlert';
import RecommendationsWidgetUI from './RecommendationsWidgetUI';
import {
  BASEMAPS
} from "lib/react-geo-tool/packages/react-basemaps/src/basemaps/basemaps";
import useDataQuery from 'api/hooks/useDataQuery';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'components/providers/TranslationProvider';
import {
  setLayerRecommendations,
  setListRecommendationsWidgetForDelete,
  setSelectWidgetId,
  setUpdateRecommendationLayerFromWidget,
  setAsDeletedRecommendation,
  setListFilterPolygonData,
  setWidgets
} from 'store/appSlice';
import useDataSetColumns from 'api/hooks/useDataSetColumns';
import {useAuth} from 'components/providers/AuthProvider';
import useGeneratePotentialOffers from 'api/hooks/useGeneratePotentialOffers';
import useDataQueryDrop from 'api/hooks/useDataQueryDrop';
import apiPaths from 'api/apiPaths';
import axios from 'api/axios/axiosInstance.js';
import {status200} from 'api/status.utils.js';
import useGenerateDynamicRecommendationsName
  from 'api/hooks/useGenerateDynamicRecommendationsName';
import isEqual from 'lodash/isEqual';
import {useLayers} from 'components/providers/LayersProvider';
import {processJobStatus} from 'utils/constants';
import useFilterQueryBuilder from 'api/hooks/useFilterQueryBuilder';

/**
 * Renders a <RecommendationsWidget /> component
 * @param  {object} props
 * @param  {string} props.id - ID for the widget instance.
 * @param  {string} props.title - Title to show in the widget header.
 * @param  {string} props.dataSource - ID of the data source to get the data from.
 * @param  {string} props.column - Name of the data source's column to get the data from.
 * @param  {Object} [props.wrapperProps] - Extra props to pass to [WrapperWidgetUI](https://storybook-react.carto.com/?path=/docs/widgets-wrapperwidgetui--default)
 */

function RecommendationsWidget(props) {
  const {
    id,
    title,
    datasetName,
    wrapperProps = {},
    dataSource,
    columns,
    global = false,
    noDataAlertProps = {},
    droppingFeaturesAlertProps,
    customData,
    tooltip = true,
  } = props;
  const source = useSelector((state) => selectSourceById(state, dataSource) || {});
  const {filters} = source;
  const dispatch = useDispatch();
  const basemap = useSelector((state) => BASEMAPS[state.carto.basemap]);
  const activeWidget = useSelector((state) => state.app.selectWidgetId);
  const spatialFilters = useSelector((state) => state.app.listFilterPolygonData);
  const widget = useSelector((state) => state.app.widgets.find(w => w.id === id));
  const sources = useSelector((state) => state.carto.dataSources);
  const isRecommendationLayerFromWidgetUpdated = useSelector((state) => state.app.updateRecommendationLayerFromWidget);
  const [operator, setOperator] = useState('and');
  const [demandDatasetName, setDemandDatasetName] = React.useState(widget?.params?.demandDatasetName);
  const [spatialFilter, setSpatialFilter] = useState();
  const [targetDemandFilter, setTargetDemandFilter] = useState('');
  const [currentOfferFilter, setCurrentOfferFilter] = useState('');
  const [processJobStatusPotOffers, setProcessJobStatusPotOffers] = useState('');
  const [parsedData, setParsedData] = useState({
    columns: [],
    values: []
  });
  const [widgetProcessJobStatus, setWidgetProcessJobStatus] = React.useState(processJobStatus.WAITING);
  const [potentialOffersComputionData, setPotentialOffersComputionData] = useState({});
  const [recommendationsComputionData, setRecommendationsComputionData] = useState({});
  const [processJobName, setProcessJobName] = useState('');

  const {t, lng} = useTranslation();
  const widgets = useSelector((state) => state.app.widgets);
  const layers = useSelector((state) => state.app.layers);

  const {user} = useAuth();
  const location = useLocation();
  const splittedPathname = React.useMemo(() => location.pathname.split('/'), [location.pathname]);
  const org_id = splittedPathname[2] === 'workspace' ? user.id : splittedPathname[2];
  const zoomLevel = useSelector((state) => Math.floor(state.carto.viewState.zoom));
  const filterGeometries = useSelector((state) => state.carto.spatialFilter?.geometry?.coordinates);
  const widgetFiltersForTargetDemand = useSelector((state) => state.app.widgetFiltersForTargetDemand);
  const widgetFiltersForCurrentOffer = useSelector((state) => state.app.widgetFiltersForCurrentOffer);

  const listRecommendationsWidgetForDelete = useSelector((state) => state.app.listRecommendationsWidgetForDelete);
  const selectedWidgetId = useSelector((state) => state.app.selectWidgetId);
  const selectedWidgetEntity = useSelector((state) => (state.app.recomputedRecommendationsWidgets.find(w => w.id === selectedWidgetId) || state.app.widgets.find(w => w.id === selectedWidgetId)));
  const {changeVisible} = useLayers();
  const [offerTypes, setOfferTypes] = useState(['recommendation', 'recommended_expansion', 'current_offer']);
  const [idSortedType, setIdSortedType] = useState( '' );
  const [columnSortedType, setColumnSortedType] = useState( '' );
  const [recommendationsFiltered, setRecommendationsFiltered] = useState({'rows': []});
  const columnsToOrder = [
    {value: 'w_var_covered', label: t('recommendations_priority_header')},
    {value: 'quant_var_covered', label: t('recommendations_pc_header')}
  ];
  const [selectedColumnToOrder, setSelectedColumnToOrder] = useState('w_var_covered');
  const recommendationsFilteredTemp = React.useRef({});
  const queryBuilder = useFilterQueryBuilder({ operator });

  const handleSelect = React.useCallback(
    ({lat: latitude, lon: longitude}) => {

      if (basemap === 'positron' || basemap === 'voyager' || basemap === 'dark-matter') {
        dispatch(
          setViewState({
            zoom: 13,
            latitude,
            longitude,
          })
        )
      } else {
        let counter = 1;

        if (zoomLevel > 13) {
          dispatch(
            setViewState({
              zoom: 13,
            })
          );
        }

        if (zoomLevel > 10) {
          setTimeout(() => {
            dispatch(
              setViewState({
                zoom: 10
              })
            );
          }, counter * 450);
          counter += 1;
        }

        if (zoomLevel > 7) {
          setTimeout(() => {
            dispatch(
              setViewState({
                zoom: 7
              })
            );
          }, counter * 480);
          counter += 1;
        }

        setTimeout(() => {
          dispatch(
            setViewState({
              zoom: 9,
              latitude,
              longitude,
            })
          );
        }, counter * 510);
        counter += 1;

        setTimeout(() => {
          dispatch(
            setViewState({
              zoom: 11,
              latitude,
              longitude,
            })
          );
        }, counter * 540);
        counter += 1;

        setTimeout(() => {
          dispatch(
            setViewState({
              zoom: 13,
              latitude,
              longitude,
            })
          );
        }, counter * 575);
        counter += 1;

        setTimeout(() => {
          dispatch(
            setViewState({
              zoom: 16,
              latitude,
              longitude,
            })
          );
        }, counter * 610);
      }
    },
    [dispatch, zoomLevel]
  );


  React.useEffect(() => {
    if (activeWidget && spatialFilter) {
      const key = `${activeWidget}-${(location.pathname.split('/'))[6]}`;
      setSpatialFilter(spatialFilters[key]);
    } else {
      setSpatialFilter();
    }
  }, [activeWidget]);

  React.useEffect(() => {
    if (spatialFilter && filteredData[key]?.widgetId == activeWidget && widget.id == activeWidget) {
      handleFilterRecommendations();
    }
  }, [listRecommendationsWidgetForDelete]);

  React.useEffect(() => {
    if (isRecommendationLayerFromWidgetUpdated) {
      dispatch(setUpdateRecommendationLayerFromWidget(false));
    }
  }, [isRecommendationLayerFromWidgetUpdated]);

  const runFilterHandler = () => {
    setRecommendationsFiltered(recommendationsFilteredTemp.current);
  }

  const {
    mutateAsync: generatePotentialOffers,
    data: potentialOffersData,
    status: potentialOffersDataStatus,
  } = useGeneratePotentialOffers();

  const {
    mutateAsync: dropDatasetTableByName,
    data: dropDatasetTableByNameData,
    status: dropDatasetTableByNameStatus,
    isSuccess: dropDatasetTableByNameIsSuccess,
    isLoading: dropDatasetTableByNameIsLoading,
  } = useDataQueryDrop();

  const scheme_id = location.pathname.split('/')[6];

  const getDynamicRecommendationsName = (isWidgetGlobal) => {
    let dynamicRecommendationsName = '';
    const org_id = location.pathname.split('/')[2];

    if (isWidgetGlobal || isWidgetGlobal === null || isWidgetGlobal === undefined) {
      dynamicRecommendationsName = scheme_id + '_' + org_id;
    } else {
      dynamicRecommendationsName = scheme_id + '_' + user.id;
    }
    return dynamicRecommendationsName;
  }

  const dynamicRecommendationsName = getDynamicRecommendationsName(widget?.params?.isGlobal);

  const recommendationsDatasetName = `incluia-project.incluia_datasets.user_${dynamicRecommendationsName}_scenario_${customData.widgetId}_recommended_offers`;
  const recommendationsDatasetColumns = ' ido, w_var_covered, quant_var_covered, geom, expansion_recommended,fixed, (CASE WHEN fixed=true THEN "Current" ELSE "Recommended" END) AS offer_type,st_asgeojson(st_geogfromtext(isochrone)) as geo_isochrone, expansion_cost, expanded_capacity';

  const recommendationsDatasetColumnsIsochrone = ' st_asgeojson(st_geogfromtext(isochrone)) as geo_isochrone';
  const condition = 'isochrone != \'EMPTY_POLYGON\''

  const convertOpacityValuesIfNecessary = (value) => {
    if (value > 1) {
      return value / 100
    }
    return value;
  }

  const {
    data: listData,
    refetch: refetchRecommendationsOffers,
  } = useDataQuery(recommendationsDatasetName, recommendationsDatasetColumns);

  const {
    data: listDataIsochrone,
    refetch: refetchListDataIsochrone,
  } = useDataQuery(recommendationsDatasetName, recommendationsDatasetColumnsIsochrone, condition);

  React.useEffect(() => {
    if (listData) {
      const listDataCopy = {rows: listData?.rows?.map(obj => ({...obj})) || []};
      recommendationsFilteredTemp.current = listDataCopy;
      setRecommendationsFiltered(listDataCopy);
    }
  }, [listData]);

  React.useEffect(() => {
    if (listData) {
      const listDataCopy = {rows: listData?.rows?.map(obj => ({...obj})) || []};
      const filteredRows = [];

      if (offerTypes.includes('recommended_expansion')) {
        const recommendedExpansion = listDataCopy.rows.filter(recommendation => !recommendation.fixed && recommendation.expansion_recommended);
        filteredRows.push(...recommendedExpansion);
      }

      if (offerTypes.includes('recommendation')) {
        const recommendations = listDataCopy.rows.filter(recommendation => !recommendation.fixed);
        filteredRows.push(...recommendations);
      }

      if (offerTypes.includes('current_offer')) {
        const currentOffers = listDataCopy.rows.filter(recommendation => recommendation.fixed);
        filteredRows.push(...currentOffers);
      }

      const listDataCopyContainer = {rows: filteredRows};
      recommendationsFilteredTemp.current = {...listDataCopyContainer};
    }
  }, [offerTypes]);

  React.useEffect(() => {
    const listToOrder = recommendationsFiltered.rows.map(obj => ({...obj}));
    const recomendationsOrderedList = {rows: []};
    if (idSortedType === 'ASC' || !idSortedType) {
      recomendationsOrderedList.rows = [...listToOrder].sort((a, b) => a.ido.localeCompare(b.ido));
    } else {
      recomendationsOrderedList.rows = [...listToOrder].sort((a, b) => b.ido.localeCompare(a.ido));
    }
    recommendationsFilteredTemp.current = {...recomendationsOrderedList};
  }, [idSortedType]);


  React.useEffect(() => {
    const listToOrder = recommendationsFiltered.rows.map(obj => ({...obj}));
    const recomendationsOrderedList = {rows: []};
    if (columnSortedType === 'ASC' || !columnSortedType) {
      recomendationsOrderedList.rows = [...listToOrder].sort((a, b) => a[selectedColumnToOrder] - b[selectedColumnToOrder]);
    } else {
      recomendationsOrderedList.rows = [...listToOrder].sort((a, b) => b[selectedColumnToOrder] - a[selectedColumnToOrder]);
    }
    recommendationsFilteredTemp.current = {...recomendationsOrderedList};
  }, [columnSortedType]);

  React.useEffect(() => {
    let dataR = [], dataCO = [];
    let payload = {};
    let colorRA, colorCOA, opacityRA, opacityCOA;
    if (recommendationsFiltered) {
      recommendationsFiltered.rows.forEach(element => {
        let geometry;
        if (element.fixed) {
          geometry = JSON.parse(element.geo_isochrone);
          dataCO.push({
            properties: {
              ido: element.ido,
              w_var_covered: element.w_var_covered,
              quant_var_covered: element.quant_var_covered,
            },
            coordinates: geometry.coordinates[0],
            headers: ['ido_recommendations_widget_header', 'w_var_covered_recommendations_widget_header', 'quant_var_covered_recommendations_widget_header'],
          });
        } else {
          geometry = JSON.parse(element.geo_isochrone);
          dataR.push({
            properties: {
              ido: element.ido,
              w_var_covered: element.w_var_covered,
              quant_var_covered: element.quant_var_covered,
            },
            coordinates: geometry.coordinates[0],
            headers: ['ido_recommendations_widget_header', 'w_var_covered_recommendations_widget_header', 'quant_var_covered_recommendations_widget_header'],
          });
        }

      });
      colorRA = widget ? widget.params.layersConfiguration.recommendedAreaLayerColor : null; // recommended layer color
      opacityRA = widget ? convertOpacityValuesIfNecessary( widget.params.layersConfiguration.recommendedAreaLayerOpacity ) : null; // recommended layer opacity
      if (widget?.params?.currentOfferDatasetName !== '') {
        colorCOA = widget ? widget.params.layersConfiguration.currentOfferAreaLayerColor : null; // current offer layer color
        opacityCOA = widget ? convertOpacityValuesIfNecessary( widget.params.layersConfiguration.currentOfferAreaLayerOpacity ) : null; // current offer layer opacity
        payload = {id: id+'_fixed_true_co_area' , visible: widget.params.layersConfiguration.currentOfferAreaLayerVisibility, geometries: dataCO , layerAreaColor : colorCOA, layerOpacity: opacityCOA};
        layers.map((l) => {
          if (l.isRecommendation && payload.id.includes(l.id.split('_')[0])) {
            payload = {...payload, visible: payload.visible ? l.visible : false}
          }
        })
        dispatch(setLayerRecommendations(payload));
        payload = {
          id: id + '_fixed_false_r_area',
          visible: widget.params.layersConfiguration.recommendedAreaLayerVisibility,
          geometries: dataR,
          layerAreaColor: colorRA,
          layerOpacity: opacityRA
        };
        layers.map((l) => {
          if (l.isRecommendation && payload.id.includes(l.id.split('_')[0])) {
            payload = {...payload, visible: payload.visible ? l.visible : false}
          }
        })
        dispatch(setLayerRecommendations(payload));
      } else {
        payload = {
          id: id + '_fixed_false_r_area',
          visible: widget.params.layersConfiguration.recommendedAreaLayerVisibility,
          geometries: dataR,
          layerAreaColor: colorRA,
          layerOpacity: opacityRA
        };
      }
      layers.map((l) => {
        if (l.isRecommendation && payload.id.includes(l.id.split('_')[0])) {
          payload = {...payload, visible: payload.visible ? l.visible : false}
        }
      })
      dispatch(setLayerRecommendations(payload));
    }
  }, [listDataIsochrone, recommendationsFiltered, layers])

  const parseColumnsFromRecommendationEngineResponse = (columns) => {
    if (!columns || columns.length === 0) {
      return [];
    }
    columns = columns.filter((item) => {
      return item !== 'geo_isochrone' && item !== 'geom'
    })
    columns.push('id');
    return columns.map((item) => {
      return {
        field: item,
        name: t(`${item}_recommendations_widget_header`),
        visible: item !== 'fixed' && item !== "id",
      }
    });
  }

  let dataGridColumns;

  React.useEffect(() => {
    if (dropDatasetTableByNameStatus === 'success') {
      refetchRecommendationsOffers();
      dispatch(setUpdateRecommendationLayerFromWidget(true));
    }
  }, [dropDatasetTableByNameStatus]),

    React.useEffect(() => {
      if (recommendationsFiltered) {
        let columnsRecommendationEngineResponse = null;
        if (recommendationsFiltered?.rows.length > 0) {
          columnsRecommendationEngineResponse = Object.getOwnPropertyNames(recommendationsFiltered?.rows[0]);
        }
        dataGridColumns = parseColumnsFromRecommendationEngineResponse(columnsRecommendationEngineResponse);
        setParsedData({
          columns: dataGridColumns,
          values: recommendationsFiltered?.rows.map((item, index) => {
            return ({
              ...item,
              index
            })
          }),
        });
      }
    }, [recommendationsFiltered]);

  React.useEffect(() => {
    const demandQuery = queryBuilder(widget.layer);
    let currentOfferQuery = '';
    if (widget.params.isOfferSelected) {
      currentOfferQuery = queryBuilder(widget.params.currentOfferLayerName);
    }
    if (demandQuery) {
      setTargetDemandFilter(demandQuery);
    } else {
      setTargetDemandFilter('');
    }
    if (currentOfferQuery && widget.params.isOfferSelected) {
      setCurrentOfferFilter(currentOfferQuery);
    } else {
      setCurrentOfferFilter('');
    }
  }, [sources]);

  React.useEffect(() => {
    const activeEntityWidget = widgets.find(w => w.id === activeWidget);
    let newMapState;
    const outdatedWidgetIndex = widgets.findIndex(w => w.id === activeWidget);
    const updatedWidgets = [...widgets];
    const updatedWidget = {...updatedWidgets[outdatedWidgetIndex]};
    updatedWidget.params = {...updatedWidget.params};

    if (widgetProcessJobStatus === processJobStatus.INPROGRESS && processJobName === 'compute') {
      if (!activeEntityWidget.params.isGlobal) {
        newMapState = {
          user_id: org_id,
          scheme_id: location.pathname.split('/')[6],
          mapState: {
            recos: [{
              id: activeWidget,
              ...potentialOffersComputionData,
              widgetFiltersForCurrentOffer,
              widgetFiltersForTargetDemand,
            }],
          },
        };
      } else {
        newMapState = {
          user_id: org_id,
          scheme_id: location.pathname.split('/')[6],
          mapState: {
            global_map_state: {
              widgets: [
                {
                  id: activeWidget,
                  ...activeEntityWidget,
                  params: {
                    ...activeEntityWidget.params,
                    ...potentialOffersComputionData,
                  },
                  custom: {
                    ...activeEntityWidget.custom,
                    widgetFiltersForTargetDemand
                  }
                }
              ]
            },
          },
        };
      }
      axios.post(apiPaths.save_map_state, {...newMapState}, status200).then(() => {
        const updatedCustom = {...updatedWidget.custom};
        const updatedParams = {...updatedWidget.params};

        if (activeEntityWidget.params.isGlobal) {
          updatedCustom.widgetFiltersForTargetDemand = widgetFiltersForTargetDemand.map(obj => ({...obj}));
          updatedWidget.custom = updatedCustom;
        } else {
          updatedParams.widgetFiltersForTargetDemand = widgetFiltersForTargetDemand.map(obj => ({...obj}));
          updatedWidget.params = updatedParams;
        }
        updatedWidgets[outdatedWidgetIndex] = updatedWidget;
        dispatch(setWidgets(updatedWidgets));
      });
    } else if (widgetProcessJobStatus === processJobStatus.INPROGRESS && processJobName === 'generate') {
      if (!activeEntityWidget.params.isGlobal) {
        newMapState = {
          user_id: org_id,
          scheme_id: location.pathname.split('/')[6],
          mapState: {
            recos: [{
              id: activeWidget,
              ...recommendationsComputionData,
              widgetFiltersForCurrentOffer,
              widgetFiltersForTargetDemand,
            }],
          },
        };
      } else {
        newMapState = {
          user_id: org_id,
          scheme_id: location.pathname.split('/')[6],
          mapState: {
            global_map_state: {
              widgets: [
                {
                  id: activeWidget,
                  ...activeEntityWidget,
                  params: {
                    ...activeEntityWidget.params,
                    ...potentialOffersComputionData,
                    ...recommendationsComputionData,
                    ...selectedWidgetEntity.params
                  },
                  custom: {
                    ...activeEntityWidget.custom,
                    widgetFiltersForCurrentOffer,
                    widgetFiltersForTargetDemand,
                  }
                }
              ]
            },
          },
        };
      }
      axios.post(apiPaths.save_map_state, {...newMapState}, status200).then(() => {
        const updatedCustom = {...updatedWidget.custom};
        const updatedParams = {...updatedWidget.params};

        if (activeEntityWidget.params.isGlobal) {
          updatedCustom.widgetFiltersForCurrentOffer = widgetFiltersForCurrentOffer.map(obj => ({...obj}));
          updatedCustom.widgetFiltersForTargetDemand = widgetFiltersForTargetDemand.map(obj => ({...obj}));
          updatedWidget.custom = updatedCustom;
        } else {
          updatedParams.widgetFiltersForCurrentOffer = widgetFiltersForCurrentOffer.map(obj => ({...obj}));
          updatedParams.widgetFiltersForTargetDemand = widgetFiltersForTargetDemand.map(obj => ({...obj}));
          updatedWidget.params = updatedParams;
        }
        updatedWidgets[outdatedWidgetIndex] = updatedWidget;
        dispatch(setWidgets(updatedWidgets.map(obj => ({...obj}))));
      });
    }
  }, [widgetProcessJobStatus, widgetFiltersForTargetDemand, widgetFiltersForCurrentOffer]);

  React.useEffect(() => {
    if (source?.filtersLogicalOperator) {
      setOperator(source.filtersLogicalOperator);
    }
  }, [source?.filtersLogicalOperator]);

  const updateListForDelete = (elements) => {
    let selection = [];
    elements?.map(item => {
      const data = {
        recommendation_id: recommendationsFiltered.rows[item].ido,
        mapId: scheme_id,
        widgetId: id,
        geometry: JSON.parse(recommendationsFiltered.rows[item].geo_isochrone)
      }
      selection.push(data);
    });
    dispatch(setListRecommendationsWidgetForDelete({id, selection}));
    dispatch(setSelectWidgetId(id));
  }

  const updateListFilter = (element) => {
    const data = {};
    if (widget.type == 'polygon') {
      element?.forEach(selection => {
        const {widgetId, mapId, polygon_id} = selection;
        const key = `${widgetId}-${mapId}`;
        if (!data[key]) {
          data[key] = {widgetId, mapId, polygons: [polygon_id]};
        } else {
          if (!data[key].polygons.includes(polygon_id)) {
            data[key].polygons.push(polygon_id);
          }
        }
      });
    } else {
      element?.forEach(selection => {
        const {widgetId, mapId, recommendation_id} = selection;
        const key = `${widgetId}-${mapId}`;
        if (!data[key]) {
          data[key] = {
            widgetId,
            mapId: useGenerateDynamicRecommendationsName(widget?.params?.isGlobal),
            polygons: [recommendation_id],
            type: 'recommendation'
          };
        } else {
          if (!data[key].polygons.includes(recommendation_id)) {
            data[key].polygons.push(recommendation_id);
          }
        }
      });
    }
    dispatch(setListFilterPolygonData(data));
  }

  const handleFilterRecommendations = () => {
    const geometries = [];
    let temp = [];
    if (activeWidget || listRecommendationsWidgetForDelete[widget.id]) {
      temp = listRecommendationsWidgetForDelete[widget.id];
    }
    temp?.forEach(element => {
      if (element.geometry['coordinates'].length != 1) {
        geometries.push(...element.geometry['coordinates']);
      } else {
        geometries.push([...element.geometry['coordinates']]);
      }
    });
    let equal = isEqual(geometries, filterGeometries);
    if (equal) {
      dispatch(removeSpatialFilter());
      dispatch(setListRecommendationsWidgetForDelete({
        id: widget.id,
        selection: []
      }));
      dispatch(setAsDeletedRecommendation(true));
      dispatch(setSelectWidgetId());
      dispatch(setListFilterPolygonData({}));
      updateListFilter([]);
    } else if (geometries.length > 0 && !equal) {
      const geometry = {
        geometry: {
          type: "Feature", properties: {},
          geometry: {
            type: "MultiPolygon",
            coordinates: geometries
          }
        }
      };
      dispatch(setSelectWidgetId(widget.id));
      dispatch(addSpatialFilter(geometry));
      updateListFilter(temp);
    } else {
      dispatch(removeSpatialFilter());
      dispatch(setListFilterPolygonData({}));
      dispatch(setSelectWidgetId());
    }
    let prevStateLayers = layers.filter(layer => layer.visible === true);
    prevStateLayers.forEach((layer) => {
      changeVisible(layer.id, false, layer.isRecommendation);
      setTimeout(() => {
        changeVisible(layer.id, true, layer.isRecommendation);
      }, 50);
    });
  }

  const getLanguage = () => {
    switch (lng) {
      case 'EN':
        return 'english';
      case 'FR':
        return 'french';
      case 'PO':
        return 'portuguese';
      case 'ES':
        return 'spanish';
      default:
        return 'english';
    }
  }
  const createPotentialOffers = async (params) => {
    const offerCapacityContent = params.isOfferCapacitySelected ? parseInt(params.offerCapacity) : 0
    let user_name = '';
    const organizationId = location.pathname.split('/')[2];
    if (widget?.params?.isGlobal) {
      user_name = scheme_id + '_' + organizationId;
    } else {
      user_name = scheme_id + '_' + user.id;
    }
    const payload = {
      payload: {
        "demand_dataset_name": demandDatasetName,
        "quant_var": widget?.params?.densityVariable,
        "w_var": widget?.params?.welfareVariable,
        "coverage": parseInt(params.offerCoverageRadius),
        "capacity_lower_bound": offerCapacityContent,
        "capacity_upper_bound": offerCapacityContent + 1,
        "cost_lower_bound": parseInt(params.offerCost),
        "cost_upper_bound": parseInt(params.offerCost) + 1,
        "user_name": user_name,
        "scenario_id": widget?.id,
        "notification_host": process.env.REACT_APP_PROCESS_JOB_ENDPOINT + apiPaths.processJob,
        "demand_custom_filter": widget?.params?.targetDemands,
        "lang": getLanguage()
      }
    }

    if (widget?.params?.currentOfferDatasetName && widget?.params?.isOfferSelected) {
      payload.payload.current_offer_dataset_name = widget?.params?.currentOfferDatasetName;
      if (widget?.params?.isOfferCapacitySelected && widget?.params?.capacityVariable) {
        payload.payload.current_offer_capacity_var = widget?.params?.capacityVariable;
      }
    }

    generatePotentialOffers(payload).then((response) => {
      params.performActionsAfterUpload(t('generating_potential_offers'));
      if (response.value) {
        params.setHeaderMessage(t('generating_potential_offers'));
        params.setTitleMessage(t('generating_potential_offers'));
        const processJobId = response.value.processJobId;
        let intervalMaxFailedRequest = 10;
        let intervalFailedRequestCount = 0;
        let intervalHandler = setInterval(() => {
          if (intervalFailedRequestCount === intervalMaxFailedRequest) {
            params.setShowProgressBar(false);
            params.setPrivateLoading(false);
            clearInterval(intervalHandler);
            const messageToastParams = {
              status: true,
              info: t('incluia_error_generating_potential_offers'),
              severity: params.messageSeverity.ERROR
            };
            params.manageMessageToast(messageToastParams);
          }

          axios.get(`${apiPaths.processJob}/${processJobId}`, status200).then(processJobResponse => {
            if (processJobResponse && processJobResponse.status === 200 && processJobResponse.data) {
              if (processJobResponse.data.value) {
                const processJob = processJobResponse.data.value;
                setProcessJobStatusPotOffers(processJob.status);
                if (processJob.status === 'in_progress') {
                  params.setProgress(processJob.progress);
                  params.setNotificationMessage(processJob.message);
                  setWidgetProcessJobStatus(processJobStatus.INPROGRESS);
                }
                if (processJob.status === params.messageSeverity.SUCCESS) {
                  const recommendationsDatasetName = `incluia-project.incluia_datasets.user_${user_name}_scenario_${widget?.id}_recommended_offers`;
                  dropDatasetTableByName(recommendationsDatasetName);
                  params.setShowProgressBar(false);
                  params.recommendationsGeneratedSuccessfully.current = true
                  params.setPrivateLoading(false);
                  clearInterval(intervalHandler);
                  setWidgetProcessJobStatus(processJobStatus.SUCCESS);
                  params.setProgress(0);
                  params.setHeaderMessage('');
                  params.setTitleMessage('');
                }
                if (processJob.status === 'failed') {
                  params.setShowProgressBar(false);
                  params.setPrivateLoading(false);
                  clearInterval(intervalHandler);
                  const messageToastParams = {
                    status: true,
                    info: `${t('incluia_error_generating_potential_offers')}: ${processJob.message}`,
                    severity: params.messageSeverity.ERROR
                  };
                  params.manageMessageToast(messageToastParams);
                  setWidgetProcessJobStatus(processJobStatus.FAILED);
                }
              } else {
                params.setShowProgressBar(false);
                params.setPrivateLoading(false);
                clearInterval(intervalHandler);
                const messageToastParams = {
                  status: true,
                  info: `${t('incluia_error_generating_potential_offers')}: ${processJob.message}`,
                  severity: params.messageSeverity.ERROR
                };
                params.manageMessageToast(messageToastParams);
                setWidgetProcessJobStatus(processJobStatus.FAILED);
              }
            } else {
              intervalFailedRequestCount++;
            }
          }).catch(err => {
            params.setShowProgressBar(false);
            params.setPrivateLoading(false);
            clearInterval(intervalHandler);
            const messageToastParams = {
              status: true,
              info: t('incluia_error_generating_potential_offers'),
              severity: params.messageSeverity.ERROR
            };
            params.manageMessageToast(messageToastParams);
            setWidgetProcessJobStatus(processJobStatus.FAILED);
          });

        }, 1600);

        const candidate = {
          offer_cost: payload.payload.cost_lower_bound,
          coverage: payload.payload.coverage,
          offer_capacity: payload.payload.capacity_lower_bound
        }

        setPotentialOffersComputionData(candidate);
      }
    });
  };

  return (
    <WrapperWidgetUI title={title} widgetId={id} {...{ ...wrapperProps, margin: '20px 14px', width: '434px' }}>
      <WidgetWithAlert
        dataSource={dataSource}
        global={global}
        droppingFeaturesAlertProps={droppingFeaturesAlertProps}
        noDataAlertProps={noDataAlertProps}
      >
        <RecommendationsWidgetUI
          data={parsedData}
          id={id}
          onSelect={handleSelect}
          customData={{
            ...customData,
            refetchRecommendationsOffers,
            refetchListDataIsochrone,
            createPotentialOffers,
            widgetProcessJobStatus,
            setWidgetProcessJobStatus
          }}
          updateListForDelete={updateListForDelete}
          targetDemandFilter={targetDemandFilter}
          currentOfferFilter={currentOfferFilter}
          dynamicRecommendationsName={dynamicRecommendationsName}
          processJobStatusPotOffers={processJobStatusPotOffers}
          handleFilterRecommendations={handleFilterRecommendations}
          offerTypesFilter={{offerTypes, setOfferTypes}}
          setProcessJobName={setProcessJobName}
          setRecommendationsComputionData={setRecommendationsComputionData}
          sortedTypeById={{idSortedType, setIdSortedType}}
          sortedTypeByColumn={{
            selectedColumnToOrder,
            setSelectedColumnToOrder,
            columnSortedType,
            setColumnSortedType
          }}
          columnsToOrder={columnsToOrder}
          runFilterHandler={runFilterHandler}
          operator={operator}
        />
      </WidgetWithAlert>
    </WrapperWidgetUI>
  );
}

RecommendationsWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dataSource: PropTypes.string.isRequired,
  column: PropTypes.string,
  onError: PropTypes.func,
  wrapperProps: PropTypes.object,
  global: PropTypes.bool,
  noDataAlertProps: PropTypes.object,
  droppingFeaturesAlertProps: PropTypes.object,
};

export default RecommendationsWidget;
