import * as React from 'react';
import {
  FormControlLabel,
  Grid,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import bing from '../../../../../../assets/icons/bing_aerial.png';
import hybrid from '../../../../../../assets/icons/google_hybrid.png';
import road from '../../../../../../assets/icons/google_road.png';
import satellite from '../../../../../../assets/icons/google_satellite.png';
import light from '../../../../../../assets/icons/prosperia_ligth.png';

import { useDispatch, useSelector } from 'react-redux';
import {
  GOOGLE_CUSTOM,
  GOOGLE_HYBRID,
  GOOGLE_ROADMAP,
  GOOGLE_SATELLITE,
  BING_AERIAL,
  GOOGLE_CUSTOM_2
} from 'lib/react-geo-tool/packages/react-basemaps/src/basemaps/basemaps';
import {removeLayer, removeSource, setBasemap} from 'lib/react-geo-tool/packages/react-redux/src/';

import { useLayers } from 'components/providers/LayersProvider';
import { useTranslation } from 'components/providers/TranslationProvider';
import CloseIcon from '@mui/icons-material/Close';
import {
  setSendMapStateToServer,
  setMapInfoBeforeReload
} from 'store/appSlice';

import {
  baseMapImageStyle,
  MuiDivControl,
  MuiGridContainer,
  MuiIconButton,
  popoverContainerStyle
} from './styles/baseMapSelector';
import axios from "../../../../../../api/axios/axiosInstance";
import apiPaths from "../../../../../../api/apiPaths";
import {status200} from "../../../../../../api/status.utils";
import {useLocation} from "react-router-dom";
import { useAuth } from 'components/providers/AuthProvider';
import { useTheme } from '../../../../../providers/CustomThemeProvider';

const baseMaps = [
  {
    name: 'Prosperia Clear',
    baseMap: GOOGLE_CUSTOM_2,
    image: light
  },
  {
    name: 'Prosperia Light',
    baseMap: GOOGLE_CUSTOM,
    image: light
  },
  {
    name: 'Google Road',
    baseMap: GOOGLE_ROADMAP,
    image: road
  },
  {
    name: 'Google Hybrid',
    baseMap: GOOGLE_HYBRID,
    image: hybrid
  },
  {
    name: 'Google Satellite',
    baseMap: GOOGLE_SATELLITE,
    image: satellite
  },
  {
    name: 'Bing Aerial',
    baseMap: BING_AERIAL,
    image: bing
  },

];
export const buttonClose= {
  color: 'black',
  '&:hover': {
    backgroundColor: 'transparent'
  },
  borderRadius: 25,
  position: 'absolute',
  right: 5,
  top: 12,
  padding: 0,
  transform: 'scale(0.7)',
}

const BaseMapSelector = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const viewState = useSelector((state) => state.carto.viewState);
  const widgetColor = useSelector((state) => state.app.widgetColor);
  const cartoDataSources = useSelector((state) => state.carto.dataSources);
  const splittedPathname = React.useMemo(() => location.pathname.split('/'), [location.pathname]);
  const { user } = useAuth();
  const id = splittedPathname[2] === 'workspace' ? user.id : splittedPathname[2];
  const basemap = useSelector((state) => state.carto.basemap);
  const { setSchemeFetched, mapRefreshCounter, setMapLayers, refreshLayers, setRefresh, setMapRefreshCounter } = useLayers();
  const { t } = useTranslation();
  const { theme } = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const isPermitSaveMapState = user?.type !== 'level_0' ? splittedPathname[1] !== 'public' ? true : false : false;

  const {
    mapComponent,
    setMapComponent,
  } = props

  const handleClose = () => setAnchorEl(null);

  const handleChange = async (e) => {
    const tempMapInfo = {
      viewState: viewState,
      widgetColor: widgetColor,
      filters: {},
    }
    Object.keys(cartoDataSources).forEach((key) => {
      if (cartoDataSources[key].filters && Object.keys(cartoDataSources[key].filters).length > 0) {
        tempMapInfo.filters[key] = cartoDataSources[key].filters;
      }
    })
    dispatch(setMapInfoBeforeReload(tempMapInfo));
    dispatch(setBasemap(e));
    dispatch(setSendMapStateToServer(true));
    const newMapState = {
      user_id: id,
      scheme_id: location.pathname.split('/')[6],
      mapState: {
        baseMap: e
      },
    };
    if (isPermitSaveMapState) await axios.post(apiPaths.save_map_state, { ...newMapState }, status200);
    setSchemeFetched((pS) => ({
      ...pS
    }));
    setMapRefreshCounter(mapRefreshCounter + 1);
    setRefresh((prevS) => !prevS);
    handleClose();
  };

  const handleComponent = (currentTarget) => {
    setMapComponent('base-map-selector');
    setAnchorEl(currentTarget);
  }

  React.useEffect(() => {
    if (mapComponent !== 'base-map-selector') {
      setAnchorEl(null);
    }
  }, [mapComponent]);

  return (
    <>
      <MuiIconButton
        color={open ? 'secondary' : 'primary'}
        onClick={(e) => handleComponent(e.currentTarget)}
      >
        <MapIcon style={{
          width: 24,
          height: 24,
          color: theme.palette.primary.main,
        }} />
      </MuiIconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        id={open ? 'simple-popover' : undefined}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={popoverContainerStyle}
      >
        <MuiGridContainer container>
          <Typography style={{fontFamily: 'Montserrat', fontSize: 14, fontWeight: 600}}>
            {t('base_map')}
          </Typography>
          <IconButton style={buttonClose} onClick={handleClose}>
            <CloseIcon/>
          </IconButton>
        </MuiGridContainer>
        <Grid container direction="column" style={popoverContainerStyle}>
          {baseMaps.map((m) => (
            <Grid item key={m.baseMap}>
              <FormControlLabel
                control={
                  <MuiDivControl
                    className={`${basemap === m.baseMap ? 'selected' : ''}`}
                    onClick={() => handleChange(m.baseMap)}
                  >
                    <img
                      src={m.image}
                      alt={m.name}
                      style={baseMapImageStyle}
                    />
                    <Typography variant={'caption'}>{m.name}</Typography>
                  </MuiDivControl>
                }
                label=""
                style={{ margin: 0 }}
              />
            </Grid>
          ))}
        </Grid>
      </Popover>
    </>
  );
};

export default BaseMapSelector;
