import { useState } from 'react';
import { Typography, IconButton, Popover, Box } from "@mui/material";
import { 
  InfoOutlined as InfoOutlinedIcon,
  Close as CloseIcon
} from '@mui/icons-material';

const LayerDescription = ({ title, description }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'layer-description' : undefined;

  return (
    <Box>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        style={{ padding: 4, border: '1px solid #E0E0E0', width: '24px', height: '24px', marginLeft: '4px' }}
        disabled={!description}
      >
        <InfoOutlinedIcon style={{ width: '16px', height: '16px', fontSize: '16px', fill: description ? '#757575' : '#BDBDBD' }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ '& .MuiPaper-root': { borderRadius: '12px' } }}
      >
        <Box 
          sx={{ 
            width: "255px", 
            maxHeight: "291px", 
            padding: '16px', 
            backgroundColor: '#FFFFFF', 
            boxShadow: '0px 4px 4px 0px #0000001A', 
            display: 'flex', 
            flexDirection: 'column',
          }}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              marginBottom: '12px' 
            }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
              {title}
            </Typography>
            <CloseIcon 
              style={{ width: '16px', height: '16px', fontSize: '16px', cursor: 'pointer' }} 
              onClick={handleClose} 
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              overflowY: 'auto',
            }}
          >
            <Typography variant="body2" sx={{ color: '#424242', lineHeight: '17.07px', letterSpacing: '0.035px' }}>
              {description}
            </Typography>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default LayerDescription;