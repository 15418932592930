import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'components/providers/TranslationProvider';
import TypographyWithInfoIconTooltip from 'components/common/TypographyWithInfoIconTooltip';
import {
  filterDifferenceContainerStyle, filterDifferenceStyle,
  MuiFilterInfo,
  MuiFilterUnit,
  MuiFilterValue,
  MuiInfoCard,
  MuiInfoCardData,
  MuiInfoCardHeader,
  MuiVariableLabel,
  MuiVariableName,
  MuiVariableValue,
  negativePercentageStyle,
  positivePercentageStyle,
  baseLineAlign,
  prefixFilter,
} from './styles/vaiableInfo';

const VariableInfo = ( props ) => {
  const {
    prefix,
    label,
    data,
    name,
    information,
    filterData,
    filterUnit,
    isStatisticWidget=false,
    containerStyles
  } = props;
  const { t } = useTranslation();

  function safeDivide(numerator, denominator) {
    if (denominator === 0) {
      return 0;
    }
    const result = numerator / denominator;
    if (Number.isNaN(result) || !Number.isFinite(result)) {
      return 0;
    }
    return result;
  }

  const growthRate = React.useMemo(() => {
    if (data && filterData) {
      const parseData = parseFloat(data?.replace(/,/g, ''));
      const parseFilterData = parseFloat(filterData.replace(/,/g, ''));
      if ( parseData == 0 && parseFilterData > 0 ) {
        return Infinity;
      }

      else if ( parseData == 0 && parseFilterData < 0 ) {
        return -Infinity;
      }

      if (!Number.isNaN(parseData) && !Number.isNaN(parseFilterData)) {
        const rate = safeDivide((parseData - parseFilterData), parseData) * 100;
        return -1 * Math.round(rate);
      }
    }
    return 0;
  }, [ data, filterData]);

  return (
    <MuiInfoCard isStatisticWidget={isStatisticWidget} isFiltered={!!data} sx={containerStyles}>
      <MuiInfoCardHeader isStatisticWidget={true}>
        <MuiVariableLabel variant="caption" >{ label }</MuiVariableLabel>
        { information && 
        <TypographyWithInfoIconTooltip className={{ height: isStatisticWidget ? undefined : '16px' }} customBtnStyles={{ padding: isStatisticWidget ? undefined : 0, verticalAlign: isStatisticWidget ? 'middle' : 'unset' }}>
          { t( information ) }
        </TypographyWithInfoIconTooltip>}
      </MuiInfoCardHeader>
      <MuiInfoCardData>
        {(!!prefix)&&(
          <MuiVariableName variant='overline' sx={{ marginRight: '5px', ...baseLineAlign }}>
            {prefix||''}
          </MuiVariableName>
        )}
        <MuiVariableValue sx={baseLineAlign}>
          { filterData || 0 }
        </MuiVariableValue>
        {(!!name)&&(
          <MuiVariableName
            sx={{marginTop:'0px !important', ...baseLineAlign}}
            variant='overline'>
            {`${name}`}
          </MuiVariableName>
        )}
      </MuiInfoCardData>
      { data && (
        <MuiFilterInfo>
          <Box sx={{ display: 'inline-flex', alignItems: 'center', overflow: 'hidden' }} >
            { (!!prefix) && (<MuiFilterUnit variant='overline' sx={prefixFilter} >
              {prefix}
            </MuiFilterUnit>)}
            <MuiFilterValue>
              {data || 0}
            </MuiFilterValue>
            <MuiFilterUnit variant='overline' >
              {filterUnit}
            </MuiFilterUnit>
          </Box>
          <Box 
            display='inline-flex' 
            alignItems='center' 
            justifyContent='center'
            sx={{
              ...filterDifferenceContainerStyle, 
              ...(growthRate >= 0 ? positivePercentageStyle : negativePercentageStyle)
            }}
          >
            <Typography sx={filterDifferenceStyle}>
              {`${growthRate > 0 && Math.abs( growthRate ) !== Infinity ? '+' : ''}${ Math.abs( growthRate ) !== Infinity ? growthRate + '%' : growthRate >= 0 ? '+∞' : '-∞'  }`}
            </Typography>
          </Box>
        </MuiFilterInfo>
      )}
    </MuiInfoCard>
  );
};

export default VariableInfo;
